export class UrlParams {
  public static readonly HOST_BACKEND = [
    {
      host: 'http://localhost:4200',
      backend: 'https://whonhow.com/adInjectorApi/api'
      // backend: 'https://whonhow.com/stagingapi/api'
      // backend: 'http://localhost:5174/api'
    },
    {
      host: 'http://whonhow.com',
      backend: 'http://whonhow.com/adInjectorApi/api'
    },
    {
      host: 'https://whonhow.com',
      backend: 'https://whonhow.com/adInjectorApi/api'
    },
    // {
    //   host: 'http://whonhow.com',
    //   backend: 'http://whonhow.com/stagingapi/api'
    // },
    // {
    //   host: 'https://whonhow.com',
    //   backend: 'https://whonhow.com/stagingapi/api'
    // }

  ];
  public static ACTIVE_HOST: string;
  private static API: string;
  public static IMAGE_HOST_URL = 'https://whonhow.com/adInjectorApi/content/';
  //public static IMAGE_HOST_URL = 'https://whonhow.com/stagingapi/content/';
  //public static IMAGE_HOST_URL='http://localhost:5174/content/';
  public static init(host: string) {
    const hostBackend = this.HOST_BACKEND.find((hb) => host.startsWith(hb.host));
    if (!hostBackend) {
      alert(`Unknown hub ${host}`);
    } else {
      this.API = `${hostBackend.backend}`;
      this.ACTIVE_HOST = host;
    }
  }

  public static get ACCOUNT(): any {
    const param = `${this.API}/Account`;
    return {
      USER_LOGIN: `${param}/Login`,
      CREATE_USER: `${param}/UserRegistration`,
      UPDATE_PASSWORD: `${param}/ChangePassword`,
      FORGET_PASSWORD: `${param}/ForgetPassword`,
      CREATE_SHARELINKLOG: `${param}/ShareLinkLog`,
      CREATE_GUEST_USER: `${param}/GuestUserRegistration`,
      UPDATE_GUEST_USER: `${param}/UpdateGuestUser`,
      CREATE_GUEST_PROJECT: `${param}/GuestProjectMapping`,
    }
  }

  public static get COMPANY(): any {
    const param = `${this.API}/Company`;
    return {
      CREATE_COMPANY: `${param}/CompanyAdd`,
      GET_EDITCOMPANY: `${param}/updatecompany`,
      GET_COMPANYDATALIST: `${param}/GetCompanyDataList`,
      ADD_AFFILIATEPARTNER: `${param}/AddAffiliatePartner`,
      DELETE_AFFILIATEPARTNER: `${param}/DeleteAffiliatePartner`
    }
  }

  public static get ADWARE():any{
    const param = `${this.API}/AdwareHunting`;
    return {
      CREATE_ADWARE: `${param}/AddAdware`,
      GET_ADDWARELISTS: `${param}/adwarelist`,
      CREATE_APPBEHAVIOR: `${param}/AppBehavior`,
      GET_APPBEHAVIORLIST: `${param}/GetappbehaviorList`,
      GET_ADWAREAPPBEHAVIOR: `${param}/GetAdwareAppbehavior`,
      GET_EDITAPPBEHAVIOR: `${param}/updateappbehavior`,
      GET_ADDWARELIST: `${param}/Getadwarelist`,
      GET_ADWARECOUNT: `${param}/GetAdwareCount`,
      GET_IDENTIFICATIONTYPECOUNT: `${param}/GetresourceCount`,
      GET_ADWARERESOURCECOUNT: `${param}/GetadwaresbyResource`,
      GET_APPBEHAVIORCOUNT: `${param}/GetadwareappbehaviorCount`,
      GET_ADDWAREWISECOUNT: `${param}/AdwareWiseCount`,
      GET_ADWAREWISEDETAILEDCOUNT: `${param}/AdwareWiseDetailedCount`,
      GET_GETDOMAINLIST: `${param}/GetDomainList`,
      GET_BRANDIMPACTEDLIST: `${param}/GetBrandImpactedList`
    }
  }

  public static get REPORTS(): any {
    const param = `${this.API}/ReportSummary`;
    return {
      CREATE_REPORT: `${param}/AddNewReport`,
      GET_EDITREPORT: `${param}/UpdateCookieStuffing`,
      CREATE_COOKIEHIJACKING: `${param}/AddCookieHijacking`,
      GET_EDITCOOKIEHIJACKING: `${param}/UpdateCookieHijacking`,
      GET_REPORTSUMMARY: `${param}/GetReportSummaryList`,
      GET_REPORTSUMMARYFILTER: `${param}/GetReportSummaryDateWiseList`,
      UPDATE_REVIEW: `${param}/ChangeStatus`,
      UPDATE_STATUS: `${param}/EditStatus`,
      CREATE_EMAIL: `${param}/EmailTrigger`,
      GET_ADDWEBPUSHNOTIFICATION: `${param}/AddWebPushNotification`,
      GET_EDITWEBPUSHNOTIFICATION: `${param}/UpdateWebpushNotification`,
      CREATE_PARKEDDOMAIN: `${param}/AddParkDomain`,
      GET_EDITPARKEDDOMAIN: `${param}/UpdateParkDomain`,
      GET_ADDBUNDLING: `${param}/AddBundling`,
      GET_EDITBUNDLING: `${param}/UpdateBundling`,
      CREATE_WEBSITENOTIFICATION: `${param}/AddWebsiteNotification`,
      GET_EDITWEBSITENOTIFICATION: `${param}/UpdateWebsiteNotification`,
      GET_COOKIESTUFFING: `${param}/GetCookieStuffingList`,
      GET_PARAMLIST: `${param}/GetParamName`,
      GET_VALIDATIONLIST: `${param}/GetValidFields`,
      GET_PARKEDDOMAINLIST: `${param}/GetParkedDomainList`,
      GET_RESOURCE_WISE_COUNT: `${param}/GetResourceWiseCount`,
      GET_PARAM_LIST: `${param}/GetParamList`,
      GET_PARAM_VALUE_COUNT: `${param}/GetParamValueCount`,
      GET_PARAM_VALUE_WISE_REPORT: `${param}/GetParamValueWiseReport`,
      GUEST_PROJECT_MAPPED_DATA: `${param}/GetGuestMappedData`
    }
  }
  
  public static get DASHBOARDDATA(): any {
    const param = `${this.API}/Dashboard`;
    return {
      GET_DASHBOARDDATA: `${param}/GetDashboardData`,
      GET_COMPANYWISELIST: `${param}/GetCompanyWiseData`,
      GET_DASHBOARDCHARTDATA: `${param}/GetDashboardChartData`,
      GET_DASHBOARDWEEKLYCHARTDATA: `${param}/GetDashboardweeklyChartData`,
      GET_PROXYCHARTDATA: `${param}/GetProxychartdata`,
      GET_PLATFORMCHARTDATA: `${param}/GetPlatformchartdata`,
      GET_VIOLATIONCHARTDATA: `${param}/GetViolationchart`,
      GET_EMAILTEMPLATELIST: `${param}/GetEmailTemplate`,
      ADD_EMAILTEMPLAT: `${param}/EmailAdd`,
      GET_EMAILTEMPLATE: `${param}/updateemailtemplate`,
      GET_INCEDENTIDLIST: `${param}/GetGlobalID`,
      GET_BRANDLIST: `${param}/GetBrandCount`,
      GET_BRANDCOUNTLIST: `${param}/GetBrandCountList`,
      GET_PROJECTWISEBRANDLIST: `${param}/GetProjectWiseBrandCount`,
      Get_CompanywiseProjectCount: `${param}/GetCompanywiseProjectCount`
    }
  }
  public static get MASTERS_LIST():any{
    const param = `${this.API}/MasterList`;
    return{
      GET_COMPANY: `${param}/GetCompanyList`,
      GET_USERLIST: `${param}/GetUserList`,
      GET_GUEST_USER_LIST: `${param}/GetGuestUserList`,
      GET_COMPANYDATA: `${param}/GetCompanyData`,
      GET_COMPANYDATAFILTER: `${param}/GetCompanyDataFilter`,
      GET_COMPANYLIST: `${param}/GetCompanyList`,
      GET_COMPANYLISTSHARELINK: `${param}/GetCompanyListSharelink`,
      GET_GETCOMPANYNAMELIST: `${param}/GetCompanyNameList`,
      GET_GETSTATUSLIST: `${param}/GetStatusList`,
      GET_INSTALLATIONLIST: `${param}/GetInstallerTypeList`,
      GET_BROWSERLIST: `${param}/GetBrowserList`,
      GET_PACKAGELIST: `${param}/GetPackageList`,
      GET_ADVERTISELIST: `${param}/GetAdvertiseList`,
      GET_SEARCHENGINELIST: `${param}/GetSearchEngineList`,
      GET_PROXYLIST: `${param}/GetProxyList`,
      GET_PLATFORMLIST: `${param}/GetPlatFormList`,
      GET_NETWORKMASTER: `${param}/GetNetworkList`,
      GET_CREATIVETYPEMASTER: `${param}/GetCreativeList`,
      GET_URL: `${param}/GetUrlList`,
      GET_PAGETYPELIST: `${param}/GetPageList`,
      GET_DOMAINLIST: `${param}/GetdomainList`,
      GET_GETADWARETYPE: `${param}/GetAdwareTypeList`
    }
  }

  public static get ADD_MASTERS():any{
    const param = `${this.API}/ManageDropdown`;
    return{
      GET_STATUSLIST: `${param}/StatusList`,
      CREATE_BROWSER: `${param}/BrowserAdd`,
      CREATE_PACKAGE: `${param}/PackageAdd`,
      CREATE_BRAND: `${param}/AddBrand`,
      CREATE_ADVERTISE: `${param}/AdvertiseAdd`,
      CREATE_TEAM: `${param}/TeamAdd`,
      CREATE_ASSIGNTEAM: `${param}/TeamAssignment`,
      CREATE_SEARCHENGINE: `${param}/SearchEngineAdd`,
      CREATE_PROXY: `${param}/ProxyAdd`,
      CREATE_DOMAIN: `${param}/domainadd`,
      CREATE_PLATFORM: `${param}/PlatFormAdd`,
      CREATE_INSTALLATION: `${param}/InstallerTypeAdd`,
      GET_INSTALLATIONLIST: `${param}/installerTypeList`,
      GET_BROWSERLIST: `${param}/browserList`,
      GET_PACKAGELIST: `${param}/packageList`,
      GET_ADVERTISELIST: `${param}/advertiseList`,
      GET_SEARCHENGINELIST: `${param}/searchEngineList`,
      GET_PROXYLIST: `${param}/proxyList`,
      GET_PLATFORMLIST: `${param}/platFormList`,
      GET_NETWORKMASTER: `${param}/networkList`,
      CREATE_NETWORKMASTER: `${param}/NetworkAdd`,
      GET_CREATIVETYPEMASTER: `${param}/creativeTypeList`,
      CREATE_CREATIVETYPE: `${param}/creativeTypeAdd`,
      GET_AFFDROPDOWN: `${param}/AffModelList`,
      GET_OBSERDROPDOWN: `${param}/PageList`,
      CREATE_ADDAFFMODEL: `${param}/AppModelAdd`,
      GET_AFFMODELLIST: `${param}/affList`,
      CREATE_ADDPAGETYPE: `${param}/AddPage`,
      CREATE_VIOLATION: `${param}/AddViolationType`,
      GET_VIOLATIONLIST: `${param}/ViolationTypeList`,
      GET_TEAMLIST: `${param}/teamList`,
      GET_ASSIGNEDTEAMLIST: `${param}/AssignedTeamList`,
      GET_BRANDINGLIST: `${param}/brandList`,
      GET_AFFILIATEPARTNERLIST: `${param}/AffiliatePartnerList`,
      GET_AFFILIATE_PARTNER_SUPERADMIN: `${param}/AffiliatePartnerListSuperAdmin`,
      GET_VERTICALLIST: `${param}/VerticalList`,
      GET_EDITTEAM: `${param}/updateteam`,
      CREATE_ADWARETYPE: `${param}/AdwareTypeAdd`
    }
  }

  public static get MANAGE_MASTERS():any{
    const param = `${this.API}/ManageMaster`;
    return{
      EDIT_SEARCHENGINE: `${param}/updateSearchEngine`,
      EDIT_SEARCHENGINESTATUS: `${param}/updateSearchEnginestatus`,
      EDIT_INSTALLATION: `${param}/updateInstallationType`,
      EDIT_INSTALLATIONSTATUS: `${param}/updateInstallationTypestatus`,
      EDIT_EDITBROWSER: `${param}/updatebrowser`,
      EDIT_BROWSERSTATUS: `${param}/updatebrowserstatus`,
      EDIT_EDITPACKAGE: `${param}/updatepackage`,
      EDIT_EDITPACKAGESTATUS: `${param}/updatepackagestatus`,
      EDIT_EDITADVERTISE: `${param}/updateAdvertise`,
      EDIT_ADVERTISESTATUS: `${param}/updateAdvertisestatus`,
      EDIT_EDITPROXY: `${param}/updateproxy`,
      EDIT_PROXYSTATUS: `${param}/updateproxystatus`,
      EDIT_EDITPLATFORM: `${param}/updateplatform`,
      EDIT_PLATFORMSTATUS: `${param}/updatePlatformstatus`,
      EDIT_EDITNETWORK: `${param}/updateNetworkMaster`,
      EDIT_NETWORKSTATUS: `${param}/updateNetworkMasterstatus`,
      EDIT_CREATIVETYPE: `${param}/updateCreativeMaster`,
      EDIT_CREATIVETYPESTATUS: `${param}/updateCreativeMasterstatus`,
      EDIT_AFFMODEL: `${param}/updateAFFModel`,
      EDIT_AFFSTATUS: `${param}/updateAFFModelstatus`,
      EDIT_ADDPAGETYPE: `${param}/updatePageType`,
      EDIT_PAGETYPESTATUS: `${param}/updatePageTypestatus`,
      EDIT_VIOLATION: `${param}/updateViolationType`,
      EDIT_ADWARETYPE: `${param}/updateAdwareType`
    }
  }

  public static get PUSH_NOTIFICATION_DOMAIN(): any {
    const param = `${this.API}/ManagePushNotification`;
    return {
      CREATE_NOTIFICATION_DOMAIN: `${param}/AddPushNotificationDomain`,
      GET_PUSHDOMAINLIST: `${param}/GetPushNotificationDomainList`,
      GET_ADDPUSHNOTIFICATION: `${param}/AddPushNotificationDetails`,
      GET_PUSHNOTIFICATION: `${param}/GetPushNotificationDetailsList`
    }
  }
 
  public static get EXCELFILEUPLOAD(): any {
    const param = `${this.API}/ExcelFileUpload`;
    return {
      EXCEL_FILEUPLOAD: `${param}/AddExcelFile`,
      DOMAINEXCEL_FILEUPLOAD: `${param}/adddomainexcel`
    }
  }
  public static get VALIDATIONCHECK(): any {
    const param = `${this.API}/ValidationChecklist`;
    return {
      ADD_VALIDATION: `${param}/AddValidation`,
      GET_VALIDATIONCHECK: `${param}/ValidationList`,
      EDIT_VALIDATION: `${param}/updateValidation`
    }
  }
  public static get LEAD_GENERATION(): any {
    const param = `${this.API}/LeadGeneration`;
    return {
      CREATE_ADDLEAD: `${param}/CreateLead`,
      CREATE_TASK: `${param}/CreateTask`,
      CREATE_ASSIGNLEAD: `${param}/AssignLead`,
      GET_EDITLEAD: `${param}/updateLead`,
      GET_EDITSETDEFAULT: `${param}/updateSetDefault`,
      UPDATE_RUNNINGMODE: `${param}/updateRunningMode`,
      GET_LEADLIST: `${param}/GetLeadList`,
      GET_LEADLISTEDIT: `${param}/GetLeadListEdit`,
      GET_ASSIGNEDLEADLIST: `${param}/GetLeadAssignmentList`,
      CREATE_SENDEMAIL: `${param}/EmailTrigger`,
      GET_SALES_LEAD_REPORT: `${param}/GetSalesLeadReport`,
      GET_DETAILED_LEAD_REPORT: `${param}/GetSalesLeadDetailedReport`,
      CREATE_DEAL: `${param}/CreateDeal`,
      GET_DEAL_OVERVIEW_LIST: `${param}/GetDealOverviewList`,
      GET_DEAL_MASTER_LIST: `${param}/GetDealMasterList`,
      UPDATE_NOTES: `${param}/updateNotes`,
      RECENT_COMMUNICATION_LIST: `${param}/RecentCommunicationList`,
      ACTIVITY_LOG_LIST: `${param}/ActivityLog`,
      UPDATE_READ_STATUS: `${param}/UpdateReadStatus`
    }
  }

  public static get FUZZERDOMAIN(): any {
    const param = `${this.API}/FuzzerDomain`;
    return {
      GET_FUZZERDOMAIN: `${param}/FuzzerDomain`
    }
  }
}
